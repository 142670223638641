import React from 'react'
import { CenteredSection } from '../reusable_components/layout/PageComponents.js'

const NotFoundPage = ({ t }) => (
	<CenteredSection>
		<h1>{t('copy:errorPages.notFound.pageTitle')}</h1>
		<b>{t('copy:errorPages.notFound.pageText')}</b>
		<br />
		<b>{t('copy:errorPages.errorCode')}: 404</b>
	</CenteredSection>
)

export default NotFoundPage
